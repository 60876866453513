import axios from 'axios';

export const getContractDetails = async (contractnumber: string | undefined, token: string) => {
  if (!contractnumber) return null;
  if (token) {
    const requestData = {
      onPointContractNumber: contractnumber,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    };
    const contractListRequest = await axios.get(
      `${process.env.REACT_APP_CONTRACT_SERVICE_API_ENDPOINT}/contract/details`,
      {
        headers: headers,
        params: requestData,
      },
    );
    if (contractListRequest?.data) return contractListRequest?.data;
    else return null;
  } else {
    return null;
  }
};

export const getContractList = async (
  phone: string,
  firstName: string,
  lastName: string,
  token: string,
) => {
  if (!phone || !firstName || !lastName || !token) return null;
  if (token) {
    const requestData = {
      phone,
      firstName,
      lastName,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    };
    const contractListRequest = await axios.get(
      `${process.env.REACT_APP_CONTRACT_SERVICE_API_ENDPOINT}/contract/all`,
      {
        headers: headers,
        params: requestData,
      },
    );
    if (contractListRequest?.data) return contractListRequest?.data;
    else return null;
  } else {
    return null;
  }
};
