import { useEffect, useState } from 'react';
import { Pagination, Table } from 'react-bootstrap';

interface IDataTableColumn {
  name: string;
  label: string;
  renderCell?: (item: any) => void;
}

interface DataTableProps {
  data: any;
  columns: IDataTableColumn[];
  perPageOptions: number[];
  emptyDataMessage?: string;
}

const DataTable = (props: DataTableProps) => {
  const { data = [], columns, perPageOptions, emptyDataMessage } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(perPageOptions[0]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleChangeItemsPerPage = (e: any) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);
  return (
    <div>
      <Table striped hover>
        <thead>
          <tr>
            {columns?.map((c: IDataTableColumn) => (
              <th className='bg-dark text-light' key={c.name}>
                {c.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item: any, index: number) => (
            <tr key={index}>
              {columns?.map((c: IDataTableColumn) => (
                <td key={c.name}>{c?.renderCell ? c.renderCell(item) : item[c.name]}</td>
              ))}
            </tr>
          ))}
          {!data?.length && emptyDataMessage && (
            <tr>
              <td colSpan={columns.length} className='p-3 text-center bg-light'>
                {emptyDataMessage}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <select className='form-select' value={itemsPerPage} onChange={handleChangeItemsPerPage}>
            {perPageOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <Pagination>
          {Array.from(Array(totalPages), (val, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => handleChangePage(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
    </div>
  );
};

export default DataTable;
