import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Button,
  Form,
  InputGroup,
  FormControl,
  Stack,
  Row,
  Col,
  Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/AuthContext';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../config/msalConfig';
import { getToken, getMe, microsoftLogin } from '../../../services/opw.api';
import LoginLogo from '../../../assets/Images/ServiceAvengerLogo.png';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import ForgetPasswordModal from './ForgetPasswordModal';

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

const LoginForm = () => {
  const authContext = useContext(AuthContext);
  const { instance } = useMsal();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msalLoading, setMsalLoading] = useState(false);
  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);

  const handleCloseForgetPasswordModal = () => setShowForgetPasswordModal(false);
  const handleShowForgetPasswordModal = () => setShowForgetPasswordModal(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const loginMicrosoft = async (data: any) => {
    try {
      const res = await microsoftLogin(data?.token);
      if (res?.access_token) {
        const userData = await getMe(res?.access_token);
        if (userData?.data?.user) {
          authContext.updateData('user', userData.data.user);
          authContext.updateData('token', res?.access_token);
          toast.success('You successfully logged in', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      }
      setMsalLoading(false);
    } catch (err: any) {
      toast.error(err?.response?.data?.message || 'Something Failed!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setMsalLoading(false);
    }
  };

  const microsoftHandler = () => {
    setMsalLoading(true);
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        loginMicrosoft({ token: response?.accessToken });
      })
      .catch((e: any) => {
        setMsalLoading(false);
        console.error(e);
      });
  };

  const loginUser = async (values: any) => {
    setLoading(true);
    try {
      const token = await getToken(values?.email, values?.password);
      if (token) {
        const userData = await getMe(token);
        if (userData?.data?.user) {
          authContext.updateData('user', userData.data.user);
          authContext.updateData('token', token);
          toast.success('You successfully logged in', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      }
      setLoading(false);
    } catch (err: any) {
      toast.error(err?.response?.data?.message || 'Something Failed!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      loginUser(values);
    },
  });

  useEffect(() => {
    if (authContext?.data?.token) {
      navigate('/');
    }
  }, [authContext?.data?.token, navigate]);

  return (
    <Container className='d-flex justify-content-center align-items-center vh-100'>
      <Row className='p-4 shadow'>
        <Form onSubmit={formik.handleSubmit}>
          <Row className='justify-content-center mb-4'>
            <Col xs='auto'>
              <img src={LoginLogo} alt='login logo' height='40' />
            </Col>
          </Row>
          <Row className='justify-content-center mb-4'>
            <Col xs={'auto'}>
              <Button onClick={microsoftHandler} variant='outline-dark' disabled={msalLoading}>
                Sign in with Microsoft {msalLoading && <Spinner size='sm' />}
              </Button>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col xs='auto'>
              <hr className='w-100' />
            </Col>
            <Col xs='auto'>
              <span>OR</span>
            </Col>
            <Col xs='auto'>
              <hr className='w-100' />
            </Col>
          </Row>
          <Row className='justify-content-center mb-4 text-center'>
            <span>Sign in with Email address</span>
          </Row>
          <Stack>
            <FormControl
              id='email'
              name='email'
              placeholder='Email'
              value={formik.values.email}
              onChange={formik.handleChange}
              isInvalid={formik.touched.email && !!formik.errors.email}
              style={{ minWidth: '300px' }}
            />
            {formik.touched.email && formik.errors.email && (
              <Form.Text className='text-danger'>{formik.errors.email}</Form.Text>
            )}
            <InputGroup className='mt-2'>
              <FormControl
                id='password'
                name='password'
                placeholder='Password'
                type={!showPassword ? 'password' : 'text'}
                value={formik.values.password}
                onChange={formik.handleChange}
                isInvalid={formik.touched.password && !!formik.errors.password}
              />
              <Button
                variant='outline-dark'
                onClick={handleClickShowPassword}
                onMouseDown={(e) => e.preventDefault()}
              >
                {showPassword ? <EyeSlash /> : <Eye />}
              </Button>
            </InputGroup>
            {formik.touched.password && formik.errors.password && (
              <Form.Text className='text-danger'>{formik.errors.password}</Form.Text>
            )}
            <Row className='justify-content-md-center mt-3'>
              <Col xs={'auto'}>
                <Button
                  className='mt-3'
                  variant='dark'
                  type='submit'
                  style={{ width: '150px' }}
                  disabled={loading}
                >
                  Login {loading && <Spinner size='sm' />}
                </Button>
              </Col>
            </Row>
            <Button variant='link' onClick={handleShowForgetPasswordModal}>
              Forgot Password?
            </Button>
            <ForgetPasswordModal
              show={showForgetPasswordModal}
              handleClose={handleCloseForgetPasswordModal}
            />
          </Stack>
        </Form>
      </Row>
    </Container>
  );
};

export default LoginForm;
