import { Link, useLocation } from 'react-router-dom';
import * as SideBarIcons from '../../assets/Icons/ConsumerSidebarIcons';

interface MenuItem {
  name: string;
  link: string;
  icon: string;
}

const MenuIems: MenuItem[] = [
  {
    name: 'Dashboard',
    link: '/',
    icon: SideBarIcons.dashboardIcon,
  },
  {
    name: 'My Warranty Plans',
    link: '/warrantyplans',
    icon: SideBarIcons.wPlansIcon,
  },
  {
    name: 'Register a New Warranty Plan',
    link: '/warranty/create',
    icon: SideBarIcons.newWarrantyIcon,
  },
  {
    name: 'My registered products',
    link: '/products',
    icon: SideBarIcons.regProdsIcon,
  },
  {
    name: 'Register a new product',
    link: '/products/create',
    icon: SideBarIcons.newRegIcon,
  },
  {
    name: 'Request service/File a claim',
    link: '/claimcreate',
    icon: SideBarIcons.claimsIcon,
  },
  {
    name: 'Flight Path',
    link: '/claim',
    icon: SideBarIcons.flightPathIcon,
  },
  {
    name: 'Purchase',
    link: '/purchase',
    icon: SideBarIcons.purchaseIcon,
  },
  {
    name: 'Pay Deductible',
    link: '/paydeductible',
    icon: SideBarIcons.deductibleIcon,
  },
];

interface Props {
  handleClose: (() => void) | null;
}

interface Props {
  handleClose: (() => void) | null;
}

export default function MainSideBar({ handleClose = null }: Props) {
  const location = useLocation();

  return (
    <nav
      className='d-block px-2 py-4 h-100 bg-white hidden-scrollbar'
      data-bs-theme='white'
      style={{ overflowY: 'scroll' }}
    >
      {MenuIems.map((item, idx) => {
        return (
          <Link
            key={item.name.trim()}
            to={item.link}
            onClick={() => handleClose && handleClose()}
            className={`side-bar-link p-2 rounded-pill text-decoration-none d-flex align-items-center justify-content-start flex-nowrap ${location.pathname === item.link ? 'active' : 'text-secondary'}`}
          >
            <img src={item.icon} alt={item.name} height={25} />
            <span className='text-start no-wrap ms-3 text-capitalize'>{item.name}</span>
          </Link>
        );
      })}
    </nav>
  );
}
