import axios from 'axios';

export const getFlightTrackerStatusList = async (_token: string | null = null) => {
  if (!_token) return null;
  let token = _token;
  if (token) {
    const url =
      process.env.REACT_APP_OPW_SERVICE_API_ENDPOINT + '/flighttrackerconsumer/get?isActiveFlg=1';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    };
    const detailsRequest = await axios.get(url, { headers: headers });
    if (detailsRequest?.data) return detailsRequest?.data;
    else return null;
  } else {
    return null;
  }
};

export const getCurrentDispatchStatus = async (
  _token: string | null = null,
  serviceOrderNumber: string | undefined,
) => {
  if (!_token) return null;
  let token = _token;
  if (token) {
    const url =
      process.env.REACT_APP_CLAIM_SERVICE_API_ENDPOINT +
      `/flighttracker?serviceOrderNumber=${serviceOrderNumber}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    };
    const statusRequest = await axios.get(url, { headers: headers });
    if (statusRequest?.data?.data) return statusRequest?.data?.data?.serviceOrder?.subStatus;
    else return null;
  } else {
    return null;
  }
};

export const getMappedStatus = async (_token: string | null = null, dispatchStatus: string) => {
  if (!_token) return null;
  let token = _token;
  if (token) {
    const url =
      process.env.REACT_APP_OPW_SERVICE_API_ENDPOINT +
      `/flighttracker/getStatusMap?dispatchStatus=${dispatchStatus}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    };
    const statusRequest = await axios.get(url, { headers: headers });
    if (statusRequest?.data) return statusRequest?.data?.data?.request?.flightTrackerStatusConsumer;
    else return null;
  } else {
    return null;
  }
};
