import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { forgotPassword } from '../../../services/forgetPassword.api';
import { toast } from 'react-toastify';
import './index.css';

interface ForgetPasswordModalProps {
  show: boolean;
  handleClose: () => void;
}

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
});

const ForgetPasswordModal: React.FC<ForgetPasswordModalProps> = ({ show, handleClose }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await forgotPassword(values.email);
        if (response.status === 'success') {
          toast.success('Password reset instructions sent successfully.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      } catch (error) {
        toast.error('Invalid email. Please try again.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        console.error('Error occurred while submitting forgot password form:', error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal show={show} onHide={handleClose} centered backdrop='static'>
      <Modal.Header closeButton className='dmSans'>
        <Modal.Title className='dmSans fw-bold'>Forgot Password?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className='mb-3'>
          <Col className='dmSans'>
            Enter your email below to receive password reset instructions.
          </Col>
        </Row>
        <Form onSubmit={formik.handleSubmit}>
          <Row className='mb-3'>
            <Form.Group as={Col}>
              <Form.Label className='fw-bold dmSans'>Email:</Form.Label>
              <Form.Control
                type='email'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.email && !!formik.errors.email}
              />
              <Form.Control.Feedback type='invalid' className='dmSans'>
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Button
                variant='primary'
                type='submit'
                className='w-100 border-0 bg-rgba-primary'
                disabled={loading}
              >
                {loading ? <Spinner animation="border" size="sm" /> : 'Send Email'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ForgetPasswordModal;
