// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dmSans {
  font-family: 'DM Sans', sans-serif;
}

.bg-rgba-primary {
  background-color: rgba(0, 154, 190, 1) !important;
  color: white !important;
}
.bg-rgba-primary:hover,
.bg-rgba-primary:active {
  background-color: rgba(0, 154, 190, 1) !important;
  color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth/login/index.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,iDAAiD;EACjD,uBAAuB;AACzB;AACA;;EAEE,iDAAiD;EACjD,uBAAuB;AACzB","sourcesContent":[".dmSans {\n  font-family: 'DM Sans', sans-serif;\n}\n\n.bg-rgba-primary {\n  background-color: rgba(0, 154, 190, 1) !important;\n  color: white !important;\n}\n.bg-rgba-primary:hover,\n.bg-rgba-primary:active {\n  background-color: rgba(0, 154, 190, 1) !important;\n  color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
