import axios from 'axios';
import { getToken } from './opw.api';

export const getAppointment = async (
  serviceOrderNumber: string | undefined,
  serviceAvengerContractNumber: string | undefined,
  _token: string | null = null,
) => {
  if (!serviceOrderNumber || !serviceAvengerContractNumber) return null;
  let token = _token;
  if (!token) {
    token = await getToken();
  }
  if (token) {
    const url = `${process.env.REACT_APP_CLAIM_SERVICE_API_ENDPOINT}/appointments?serviceOrderNumber=${serviceOrderNumber}&serviceAvengerContractNumber=${serviceAvengerContractNumber}`;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    };
    const detailsRequest = await axios.get(url, { headers: headers });
    if (detailsRequest?.data) return detailsRequest?.data;
    else return null;
  } else {
    return null;
  }
};

export const getMaskedAppointment = async (
  serviceOrderNumber: string | undefined,
  serviceAvengerContractNumber: string | undefined,
) => {
  if (!serviceOrderNumber || !serviceAvengerContractNumber) return null;

  const url = `${process.env.REACT_APP_CLAIM_SERVICE_API_ENDPOINT}/appointments/masked?serviceOrderNumber=${serviceOrderNumber}&serviceAvengerContractNumber=${serviceAvengerContractNumber}`;

  const headers = {
    'Content-Type': 'application/json',
  };
  const detailsRequest = await axios.get(url, { headers: headers });
  if (detailsRequest?.data) return detailsRequest?.data;
  else return null;
};
