import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { msalConfig } from '../../../config/msalConfig';
import LoginForm from './LoginForm';

const onpointMsalInstance = new PublicClientApplication(msalConfig);

let msalInstance = onpointMsalInstance;

const Login = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <LoginForm />
    </MsalProvider>
  );
};

export default Login;
