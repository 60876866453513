import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Layout } from './components/Layout';
import AuthContext from './context/AuthContext';
import NotFound from './pages/404/NotFound';
import WarrantyPlans from './pages/WarrantyPlans';
import PlanDetails from './pages/WarrantyPlans/PlanDetails';
import Login from './pages/auth/login';
import ClaimDetail from './pages/claim/details';
import ClaimDetailPub from './pages/claim/detailsPub';
import ClaimList from './pages/claim/list';
import Dashboard from './pages/dashboard';

function App() {
  const authContext = useContext(AuthContext);
  const isLoggedIn = authContext?.data?.token && authContext?.data?.user ? true : false;

  return (
    <div className='App' style={{ height: '100vh', overflow: 'hidden' }}>
      <Routes>
        <Route path='/' element={<Layout withSidebar={isLoggedIn} />}>
          <Route path='/' element={<Dashboard />} />
          <Route path='/claim' element={<ClaimList />} />
          <Route path='/claim/:id' element={isLoggedIn ? <ClaimDetail /> : <ClaimDetailPub />} />
          <Route path='/warrantyplans' element={<WarrantyPlans />} />
          <Route path='/warrantyplans/:id' element={<PlanDetails />} />
          <Route path='*' element={<NotFound />} />
        </Route>
        <Route path='/login' element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
