import { Link } from 'react-router-dom';
import claimsBg from '../../assets/Images/launcher-bg-images/claims.jpeg';
import deductiblesBg from '../../assets/Images/launcher-bg-images/deductible.jpeg';
import flightPathBg from '../../assets/Images/launcher-bg-images/flight-path.jpeg';
import paymentsBg from '../../assets/Images/launcher-bg-images/payments.jpeg';
import prodDetailsBg from '../../assets/Images/launcher-bg-images/product-details.jpeg';
import salesBg from '../../assets/Images/launcher-bg-images/sales.jpeg';
import './style.css';

interface LauncherCardItem {
  name: string;
  link: string;
  bg: string;
  classes: string;
}

const Dashboard = () => {
  const LauncherCards: LauncherCardItem[] = [
    {
      name: 'Request Service/File a Claim',
      link: 'claim',
      bg: claimsBg,
      classes: 'col-12 col-sm-6 col-md-4 launcher-card',
    },
    {
      name: 'Purchase a New Warranty',
      link: '/warranty/create',
      bg: paymentsBg,
      classes: 'col-12 col-sm-6 col-md-4 launcher-card',
    },
    {
      name: 'Warranty details, update or register a Warranty',
      link: '/warrantyplans',
      bg: salesBg,
      classes: 'col-12 col-sm-6 col-md-4 launcher-card',
    },
    {
      name: 'View Product Details, Update or Register a Product',
      link: '/products',
      bg: prodDetailsBg,
      classes: 'col-12 col-sm-6 col-md-4 launcher-card',
    },
    {
      name: 'Check your Open Job Flight Path',
      link: '/claim',
      bg: flightPathBg,
      classes: 'col-12 col-sm-6 col-md-4 launcher-card',
    },
    {
      name: 'Pay a Deductible',
      link: '/paydeductible',
      bg: deductiblesBg,
      classes: 'col-12 col-sm-6 col-md-4 launcher-card',
    },
  ];

  return (
    <div className='text-start container'>
      <h6 className='fw-normal fs-4 mb-4'>Welcome to OnPoint&apos;s Consumer Portal</h6>
      <p className='fw-light text-secondary fs-6 mb-2'>What would you like to do today?</p>
      <div className='row pb-5 mx-0'>
        {LauncherCards.map((item) => {
          return (
            <div className={`px-1 mb-2 ${item.classes}`} key={item.name.trim()}>
              <Link
                className='w-100 h-100 d-flex align-items-end justify-content-center text-capitalize text-center px-2 py-3 text-white text-decoration-none rounded launcher-card-hover'
                to={item.link}
                style={{
                  background: `linear-gradient(360deg, rgba(0, 0, 0, 0.75) 0%, rgba(4, 4, 4, 0) 50%),url(${item.bg}) center center / cover no-repeat`,
                }}
              >
                <span style={{ position: 'relative', zIndex: '1000' }}>{item.name}</span>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dashboard;
