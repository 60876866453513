import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Pagination, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import imgForBg from '../../assets/Icons/warranty-card-icon.png';
import AuthContext from '../../context/AuthContext';
import { getContractDetails } from '../../services/contract.details.api';

const PAGE_SIZE_OPTIONS = [10, 20, 50]; // Define available page size options

const TABLE_HEADINGS: string[] = [
  'Product(s) covered',
  'Product model(s)',
  'Product serials(s)',
  'Purchase Date',
  'Purchase Price',
  'Warranty Purchase Date',
  'Expiration Date',
  'Total Limits of Liability',
  // 'Terms and Conditions',
];

const PlanDetails = () => {
  const authContext = useContext(AuthContext);
  const [contractDetails, setContractDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const params = useParams();
  const init = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getContractDetails(params.id, authContext?.data?.token);
      res && setContractDetails(res.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [params.id, authContext?.data?.token])
  useEffect(() => {
    init();
  }, [init]);
  // Pagination logic
  const startIndex = (page - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, contractDetails?.contract?.product.length || 0);
  const displayedData = contractDetails?.contract?.product.slice(startIndex, endIndex);

  // Function to handle page changes
  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  // Function to handle page size changes
  const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPageSize(Number(event.target.value));
    setPage(1); // Reset page to 1 when changing page size
  };

  useEffect(() => {
    // Calculate total pages whenever data changes
    setTotalPages(Math.ceil((contractDetails?.contract?.product.length || 0) / pageSize));
  }, [contractDetails, pageSize]);

  return (
    <div className='text-start'>
      <h6 className='fw-normal fs-3 mb-5 fw-bold'>Warranty Plan Detail</h6>
      <div className='bg-white border rounded p-4'>
        {loading ? (
          <div className='d-flex justify-content-center align-items-center mt-3 mb-3'>
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        ) : contractDetails ? (
          <>
            <div className='text-start'>
              <img src={imgForBg} alt={'Plan Details'} className='' height={51} />
            </div>
            <div className='mt-3 mb-4 d-flex text-start flex-column flex-md-row'>
              <div className='mb-3 me-md-5 mb-md-0'>
                <h6 className='fw-normal fs-5'>Warranty name: </h6>
                <div>
                  <span className='text-secondary'>
                    {contractDetails?.contract.coverage.description}
                  </span>
                </div>
              </div>
              <div>
                <h6 className='fw-normal fs-5'>Contact number: </h6>
                <div>
                  <span className='text-secondary'>{contractDetails.contract.contractNumber}</span>
                </div>
              </div>
            </div>
            <div className='text-start'>
              <h6 className='fw-normal fs-5 m-0'>Products Covered: </h6>
              <div className='table-responsive'>
                <table className='table mt-4 warranty-details-table'>
                  <thead className='thead-dark'>
                    <tr className='border-0'>
                      {TABLE_HEADINGS.map((heading, idx) => {
                        return (
                          <th
                            key={heading + idx}
                            scope='col'
                            className='border-0 fw-normal'
                            style={{ textWrap: 'nowrap' }}
                          >
                            {heading}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {displayedData?.map((item: any, idx: number) => {
                      return (
                        <tr key={item?.serial + item?.subCategoryCode}>
                          <td className='text-secondary'>
                            {item?.mfg} {item?.subCategory}
                          </td>
                          <td className='text-secondary'>{item?.modelNumber}</td>
                          <td className='text-secondary'>{item?.serial}</td>
                          <td className='text-secondary'>
                            {item.productPurchaseDate === '1901-01-01T00:00:00'
                              ? 'Unknown'
                              : new Date(item.productPurchaseDate).toLocaleDateString('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                                year: 'numeric',
                              })}
                          </td>
                          <td className='text-secondary'>
                            {item.purchasePrice
                              ? '$' + Number(item.purchasePrice).toFixed(2)
                              : null}
                          </td>
                          <td className='text-secondary'>
                            {contractDetails?.contract?.saleDate
                              ? new Date(contractDetails?.contract?.saleDate).toLocaleDateString(
                                'en-US',
                                {
                                  month: '2-digit',
                                  day: '2-digit',
                                  year: 'numeric',
                                },
                              )
                              : null}
                          </td>
                          <td className='text-secondary'>
                            {contractDetails?.contract?.expirationDate
                              ? new Date(
                                contractDetails?.contract?.expirationDate,
                              ).toLocaleDateString('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                                year: 'numeric',
                              })
                              : null}
                          </td>
                          <td className='text-secondary'>
                            {item?.totalLiability
                              ? '$' + Number(item.totalLiability).toFixed(2)
                              : null}
                          </td>
                          {/* <td className='text-secondary'></td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* Pagination controls */}
            <div className='d-flex align-items-center justify-content-between mx-2 my-3'>
              <div className='flex-grow-1'>
                <select
                  name='table-length-select'
                  className='form-select w-auto'
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  {PAGE_SIZE_OPTIONS.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
              <Pagination className='m-0'>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
                  <Pagination.Item
                    key={pageNumber}
                    active={pageNumber === page}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          </>
        ) : (
          <>
            <h4 className='text-danger'>No Contracts available</h4>
          </>
        )}
      </div>
    </div>
  );
};

export default PlanDetails;
