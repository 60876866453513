import moment from 'moment';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Form, Image, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import AuthContext from '../../../context/AuthContext';
import { getClaimList } from '../../../services/claim.api';
import { IClaim } from '../../../types';
import FlightPathLogo from '../../../assets/Icons/flightPath.jpg';

const ClaimList = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState<IClaim[]>([]);
  const [error, setError] = useState('');
  const [filterInput, setFilterInput] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: 'action',
      label: '',
      renderCell: (d: IClaim) => (
        <Button
          size='sm'
          variant='outline-primary'
          onClick={() => navigate(`/claim/${d?.serviceOrderNumber}`)}
        >
          View Details
        </Button>
      ),
    },
    {
      name: 'serviceOrderNumber',
      label: 'Claim Id',
    },
    {
      name: 'createdOn',
      label: 'File Date',
      renderCell: (d: any) => formatDate(d?.createdOn),
    },
    {
      name: 'subCategory',
      label: 'Product Type',
    },
    {
      name: 'claimStatus',
      label: 'Claim Status',
    },
  ];
  const perPageOptions = [10, 50, 100];

  const formatDate = (date: string) => {
    return moment(date).format('MM/DD/YYYY');
  };

  const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterInput(e.target.value);
  };

  const filterData = (item: IClaim) => {
    if (!filterInput) return true;
    if (
      item.serviceOrderNumber?.toLowerCase().includes(filterInput?.toLowerCase()) ||
      item.subCategory?.toLowerCase().includes(filterInput?.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const list = await getClaimList(authContext?.data?.token);
        setData(list?.data as IClaim[]);
        setLoading(false);
      } catch (err) {
        setData([]);
        setError('Something went wrong!');
        setLoading(false);
      }
    };
    fetchData();
  }, [authContext]);

  useEffect(() => {
    error.length && console.error(error);
  }, [error]);

  const filteredData = Array.isArray(data) ? data?.filter(filterData) : [];

  return (
    <Fragment>
      <Image src={FlightPathLogo} alt='FlightPath' width={175} className='mb-3' />
      <p>Enter your Claim ID or Product Type to View the Current Status of your Job.</p>
      <Form.Control
        size='lg'
        type='text'
        placeholder='Ex: 123ABC'
        style={{ maxWidth: '400px' }}
        value={filterInput}
        onChange={handleFilterInputChange}
      />
      {loading ? (
        <div className='d-flex justify-content-center align-items-center mt-5 mb-3'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Fragment>
          <div className='pt-5 d-none d-sm-block'>
            <DataTable
              data={filteredData}
              perPageOptions={perPageOptions}
              columns={columns}
              emptyDataMessage={'There are no claims to display.'}
            />
          </div>
          <div className='container pt-3 d-sm-none p-2'>
            {filteredData.map((d: any) => (
              <div className='mt-3 shadow' key={d?.serviceOrderNumber}>
                <div className='bg-dark text-light p-2'>
                  {d?.serviceOrderNumber} - {d?.claimStatus}
                </div>
              </div>
            ))}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ClaimList;
