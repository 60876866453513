import React, { useContext, useEffect, useState } from 'react';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import iconimg from '../../assets/Icons/warranty-card-icon.png';
import AuthContext from '../../context/AuthContext';
import { getContractList } from '../../services/contract.details.api';

const WarrantyPlans = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        if (authContext?.data?.user) {
          const phone = authContext?.data?.user?.phone
          const firstName = authContext?.data?.user?.firstName
          const lastName = authContext?.data?.user?.lastName
          if (phone && firstName && lastName) {
            const request = await getContractList(
              phone,
              firstName,
              lastName,
              authContext?.data?.token,
            )
            if (request?.data) {
              setData(request?.data?.contractList)
            }
          }
        }
        setLoading(false)
      } catch (err) {
        console.log('err', err)
        setLoading(false)
      }
    }
    fetchData()
  }, [authContext?.data?.token, authContext?.data?.user])

  return (
    <div className='text-start'>
      <h6 className='fw-normal fs-4 mt-5 mb-4'>My Current Warranty Plans</h6>
      {!loading && (!data || data?.length === 0) && (
        <div className='p-3 text-warning shadow-sm bg-white'>
          <ExclamationTriangle size={25} color='orange' /> There are no data to display.
        </div>
      )}
      {loading ? (
        <div className='d-flex justify-content-center align-items-center mt-5 mb-3'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className='row'>
          {data &&
            data?.length > 0 &&
            data.map((d) => (
              <div className='col-12 col-sm-6 col-xl-4 col-xxl-3 p-2' key={d?.contractNumber}>
                <Link
                  to={`/warrantyplans/${d?.contractNumber}`}
                  className='text-dark text-decoration-none'
                >
                  <div className='border rounded p-3 bg-white'>
                    <div className='mb-3 text-center'>
                      <img src={iconimg} alt='' width={51} />
                    </div>
                    <div>
                      <h6 className='fw-normal fs-5 m-0'>Contract # : {d?.contractNumber}</h6>
                    </div>
                    <hr className='bg-secondary' />
                    {d?.coverageType === 'RSC' ? (
                      <>
                        <div className='fs-4'>
                          <h1>Contract Description</h1>
                          <p>{d?.coverageDesc}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='d-flex justify-content-start align-items-center mb-2'>
                          <h1 className='fs-6 m-0'>Manufacturer :</h1>
                          <p className='fs-6 mb-0 ms-2'>{d?.mfg ? d?.mfg : 'Not Available'}</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center mb-2'>
                          <h1 className='fs-6 m-0'>Model</h1>
                          <p className='fs-6 mb-0 ms-2'>
                            {d?.modelNumber ? d?.modelNumber : 'Not Available'}
                          </p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center mb-2'>
                          <h1 className='fs-6 m-0'>Manufacturer</h1>
                          <p className='fs-6 mb-0 ms-2'>
                            {d?.serial ? d?.serial : 'Not Available'}
                          </p>
                        </div>
                        <div>
                          <h1 className='fs-6'>Product Purchase Price</h1>
                          <p className='fs-6'>
                            {d?.productPurchasePrice ? d?.productPurchasePrice : 'Not Available'}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </Link>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default WarrantyPlans;
