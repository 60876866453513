/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { Image, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import C1 from '../../../assets/carousel1.jpg';
import C2 from '../../../assets/carousel3.jpg';
import C3 from '../../../assets/carousel4.jpg';
import FlightPathLogo from '../../../assets/Icons/flightPath.jpg';
import { getMaskedAppointment } from '../../../services/appointments.api';
import { getClaimMaskedDetails } from '../../../services/claim.api';
import './index.css';
// import { FlightStatusBar } from '../../../components/FlightStatusBar/FlightStatusBar';

const ClaimDetail = () => {
  const { id } = useParams();
  const [claim, setClaim] = useState<any>(null);
  const [appointmentData, setAppointmentData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [bgIndex, setBgindex] = useState(0);

  const getBgImage = () => {
    if (bgIndex === 0) {
      return C1;
    } else if (bgIndex === 1) return C2;
    else return C3;
  };

  const setBgIndex = () => {
    const time = new Date().getTime();
    setBgindex(time % 3);
  };

  function formatPhoneNumber(phoneNumber: string) {
    if (!phoneNumber) return '';
    // Remove all non-digit characters from the input phone number
    const cleaned = phoneNumber.replace(/\D/g, '');

    // Apply formatting based on the cleaned phone number
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

    return formatted;
  }

  const getAptDate = () => {
    const appointmentDate = appointmentData?.appointmentDate;
    if (!appointmentDate) return '';
    const date: Date = new Date(appointmentDate);

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    };
    const formattedDate: string = date.toLocaleDateString('en-US', options);

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    };
    const formattedTime: string = date.toLocaleTimeString('en-US', timeOptions);

    const formattedDateTime: string = `${formattedDate}   ${formattedTime}`;
    return formattedDateTime;
  };

  const init = useCallback(async () => {
    setLoading(true);
    setBgIndex();
    try {
      const data = await getClaimMaskedDetails(id);
      const aptData = await getMaskedAppointment(id, data?.data?.contractNumber);
      setClaim(data?.data);
      setAppointmentData(aptData?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [setLoading, setClaim, id]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div className='claim-details__container--pub'>
      <div className='claim-details__content'>
        <Image src={FlightPathLogo} alt='FlightPath' width={175} />
        {loading ? (
          <div className='d-flex justify-content-center align-items-center mt-3 mb-3'>
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        ) : (
          <React.Fragment>
            <div className='mt-3'>
              <h1>Contract Number: {claim?.contractNumber}</h1>
              <h1>Claim ID: {id}</h1>
            </div>
            {/* <FlightStatusBar /> */}
            <hr />
            <div>
              {claim?.product?.serial && appointmentData?.servicerName ? (
                <React.Fragment>
                  <h4>Your product with serial number</h4>
                  <div className='claim-des'>{claim?.product?.serial}</div>
                  <br />
                  <h4>has an appointment scheduled for</h4>
                  <div className='claim-des'>{getAptDate()}</div>
                  <br />
                  <h4>with</h4>
                  <div className='claim-des'>{appointmentData?.servicerName}</div>
                  <div className='claim-des'>
                    {formatPhoneNumber(appointmentData?.servicerPhone)}
                  </div>
                  <div className='claim-des'>{appointmentData?.servicerWebsite}</div>
                  <hr />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h4>Product serial number</h4>
                  <div className='claim-des'>{claim?.serial}</div>
                  <div className='claim-des fw-bold'>No appointments</div>
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
      <div className='bg'>
        <img src={getBgImage()} alt='background' />
      </div>
    </div>
  );
};

export default ClaimDetail;
