import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Image, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import C1 from '../../../assets/carousel1.jpg';
import C2 from '../../../assets/carousel3.jpg';
import C3 from '../../../assets/carousel4.jpg';
import AuthContext from '../../../context/AuthContext';
import { getAppointment } from '../../../services/appointments.api';
import { getClaimDetails } from '../../../services/claim.api';
import FlightPathLogo from '../../../assets/Icons/flightPath.jpg';
import { FlightStatusBar } from '../../../components/FlightStatusBar';
import './index.css';
import { getCurrentDispatchStatus, getMappedStatus } from '../../../services/flightTracker.api';

function formatPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) return '';
  // Remove all non-digit characters from the input phone number
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Apply formatting based on the cleaned phone number
  const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

  return formatted;
}

const ClaimDetail = () => {
  const authContext = useContext(AuthContext);
  const { id } = useParams();
  const [claim, setClaim] = useState<any>(null);
  const [appointmentData, setAppointmentData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [bgIndex, setBgindex] = useState(0);
  const [currentStatus, setCurrentStatus] = useState('');

  const getBgImage = () => {
    if (bgIndex === 0) {
      return C1;
    } else if (bgIndex === 1) return C2;
    else return C3;
  };

  const setBgIndex = () => {
    const time = new Date().getTime();
    setBgindex(time % 3);
  };

  const getClaimDispatchStatus = async () => {
    return await getCurrentDispatchStatus(authContext?.data?.token, id);
  };

  const getCurrentStatus = async () => {
    const dispatchStatus = await getClaimDispatchStatus();
    if (dispatchStatus != null) {
      try {
        const currentStatus = await getMappedStatus(authContext?.data?.token, dispatchStatus);
        setCurrentStatus(currentStatus);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const getAptDate = () => {
    const appointmentDate = appointmentData?.appointmentDate;
    const appointmentTimeSlot = appointmentData?.appointmentTimeSlot;
    if (!appointmentDate) return '';
    let date: Date = new Date(appointmentDate);

    let options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    };
    let formattedDate: string = date.toLocaleDateString('en-US', options);
    let formattedDateTime: string = `${formattedDate}   ${appointmentTimeSlot}`;
    return formattedDateTime;
  };

  const init = useCallback(async () => {
    if (!authContext?.data?.token) return;
    setLoading(true);
    setBgIndex();
    try {
      const data = await getClaimDetails(id, authContext?.data?.token);
      const aptData = await getAppointment(
        id,
        data?.data?.claim.contractNumber,
        authContext?.data?.token,
      );
      setClaim(data?.data.claim);
      getCurrentStatus();
      setAppointmentData(aptData?.data?.length > 0 ? aptData?.data[0] : null);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [setLoading, setClaim, id, authContext?.data?.token]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div className='claim-details__container'>
      <div className='claim-details__content'>
        <Image src={FlightPathLogo} alt='FlightPath' width={175} />
        {loading ? (
          <div className='d-flex justify-content-center align-items-center mt-3 mb-3'>
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Fragment>
            <div className='mt-3'>
              <h1>Claim ID: {id}</h1>
              <h3 className='text-muted'>Contract {claim?.contractNumber}</h3>
            </div>
            {currentStatus?.toLowerCase() !== 'Cancelled'.toLowerCase() ? (
              <FlightStatusBar currentStatus={currentStatus} />
            ) : (
              <Alert variant='danger'>Cancelled</Alert>
            )}
            <hr />
            <Row>
              <div className='claim-title'>
                {claim?.contact?.firstName} {claim?.contact?.lastName}
              </div>
              <div className='claim-des' style={{ whiteSpace: 'break-spaces' }}>
                {`${claim?.contact?.address?.address1}\n${claim?.contact?.address?.city}, ${claim?.contact?.address?.state} ${claim?.contact?.address?.postalCode}\n${claim?.contact?.address?.countryCode}`}
              </div>
              <div className='claim-des'>{formatPhoneNumber(claim?.contact?.phone)}</div>
              <div className='claim-des mb-3'>{claim?.contact?.email}</div>
              <hr />
              {appointmentData && (
                <Fragment>
                  <h4>Your product</h4>
                  {claim?.product?.serial && (
                    <div className='claim-des'>with serial number {claim?.product?.serial}</div>
                  )}
                  <br />
                  <h4>has an appointment scheduled for</h4>
                  <div className='claim-des'>{getAptDate()}</div>
                  <br />
                  {appointmentData?.servicerName && (
                    <Fragment>
                      <h4>with</h4>
                      <div className='claim-des'>{appointmentData?.servicerName}</div>
                    </Fragment>
                  )}
                  {appointmentData?.servicerPhone && (
                    <Fragment>
                      {' '}
                      <div className='claim-des'>
                        {formatPhoneNumber(appointmentData?.servicerPhone)}
                      </div>
                    </Fragment>
                  )}
                  {appointmentData?.servicerWebsite && (
                    <div className='claim-des'>{appointmentData?.servicerWebsite}</div>
                  )}
                  <hr className='mt-3' />
                </Fragment>
              )}
            </Row>
          </Fragment>
        )}
      </div>
      <div className='bg'>
        <img src={getBgImage()} alt='background' />
      </div>
    </div>
  );
};

export default ClaimDetail;
