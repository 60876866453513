import axios from 'axios';

export const getClaimDetails = async (serviceOrderNumber: string | undefined, _token: string) => {
  if (!_token) return null;
  if (!serviceOrderNumber) return null;
  let token = _token;
  if (token) {
    const url =
      process.env.REACT_APP_CLAIM_SERVICE_API_ENDPOINT +
      '/claim?serviceOrderNumber=' +
      serviceOrderNumber;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    };
    const detailsRequest = await axios.get(url, { headers: headers });
    if (detailsRequest?.data) return detailsRequest?.data;
    else return null;
  } else {
    return null;
  }
};

export const getClaimMaskedDetails = async (serviceOrderNumber: string | undefined) => {
  const url =
    process.env.REACT_APP_CLAIM_SERVICE_API_ENDPOINT +
    '/claim/masked?serviceOrderNumber=' +
    serviceOrderNumber;
  const headers = {
    'Content-Type': 'application/json',
  };
  const detailsRequest = await axios.get(url, { headers: headers });
  if (detailsRequest?.data) return detailsRequest?.data;
  else return null;
};

export const getClaimList = async (_token: string | null = null) => {
  if (!_token) return null;
  let token = _token;
  if (token) {
    const url = process.env.REACT_APP_CLAIM_SERVICE_API_ENDPOINT + '/claim/serviceorders';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    };
    const detailsRequest = await axios.get(url, { headers: headers });
    if (detailsRequest?.data) return detailsRequest?.data;
    else return null;
  } else {
    return null;
  }
};
